import backend from "i18next-http-backend";
import { defaultNS } from "./i18n-namespace";
import detector from "i18next-browser-languagedetector";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n
  .use(detector)
  .use(backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    backend: {
      loadPath: "/resource/v1/public?languageKey={{lng}}",
      addPath: "/resource/v1/reportMissing?languageKey={{lng}}",
    },
    debug: false,
    defaultNS: defaultNS,
    fallbackLng: "en", // use en if detected lng is not available
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;