import { useLocation, useNavigate, useParams } from "react-router-dom";
import React from "react";

/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Return a element which supports the router.
 *
 * @param {React.ElementType} Component The component.
 * @returns {(props: any) => JSX.Element} The function for a component with router properties.
 */
export function withRouter(
  Component: React.ElementType,
): (props: any) => JSX.Element
{
  /* eslint-enable @typescript-eslint/no-explicit-any */
  /**
   * Get a component with router properties.
   *
   * @param {any} props The router properties.
   * @returns {any} The component which uses router properties.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function ComponentWithRouterProp(props: any): JSX.Element
  {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}