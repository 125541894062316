import { WithTranslation, withTranslation } from "react-i18next";
import { Auth } from "aws-amplify";
import React from "react";
import { SignOut } from "@aws-amplify/ui-react/dist/types/components/Authenticator/Authenticator";
import { WithRouter } from "../interfaces/WithRouter";
import { withRouter } from "../wrapper/withRouter";

/**
 * Interface for the logout arguments.
 *
 * @interface
 * @augments {WithTranslation}
 * @augments {WithRouter}
 */
interface LogoutArguments extends WithTranslation, WithRouter
{
  /**
   * The sign out delegate.
   *
   * @property {SignOut} signOut The sign out delegate.
   */
  signOut?: SignOut;
}

/**
 * The logout.
 *
 * @class
 * @augments {React.Component<LogoutArguments>}
 */
class Logout extends React.Component<LogoutArguments>
{
  /**
   * Render the component.
   *
   * @returns {JSX.Element} The component instance.
   */
  render()
  {
    const { t } = this.props;

    Auth.signOut().then(() => 
    {
      if (this.props.signOut)
      {
        this.props.signOut();
      }

      const urlQueryParameters = new URLSearchParams(window.location.search);

      const redirectUrl = urlQueryParameters.get("redirectUrl");

      if (redirectUrl)
      {
        window.location.href = redirectUrl;
      }
      else 
      {
        this.props.router.navigate("/login");
      }
    });

    return (
      <div>
        <h1>{t("ui.loggedout")}</h1>
      </div>
    );
  }
}

export default withTranslation()(withRouter(Logout));