import {
  ENVIRONMENT_LABEL,
  LANDING_PAGE_URL,
  REGION,
  USER_POOL_CLIENT_ID,
  USER_POOL_ID,
} from "./environment";
import { I18nextProvider, useTranslation } from "react-i18next";
import { NavLink, Outlet, Route, Routes } from "react-router-dom";
import { Amplify } from "aws-amplify";
import { Authenticator } from "@aws-amplify/ui-react";
import { CognitoUserSession } from "amazon-cognito-identity-js";
import ErrorBoundary from "./components/ErrorBoundary";
import Logout from "./components/Logout";
import React from "react";
import axios from "axios";
import { defaultNS } from "./setup/i18n-namespace";
import i18n from "./setup/i18n";
import logo from "./logo.min.svg";
import "@aws-amplify/ui-react/styles.css";
import "./App.scss";

/**
 * The application.
 *
 * @class
 */
function App()
{
  console.info(`Starting MuMiver.se Login v${process.env.REACT_APP_VERSION}`);

  const darkMode
    = window.matchMedia
    && window.matchMedia("(prefers-color-scheme: dark)").matches;

  const body = document.getElementsByTagName("body")[0];

  if (body !== null && darkMode && !body.classList.contains("dark-mode"))
  {
    body.classList.add("dark-mode");
  }

  const amplifyConfig = {
    Auth: {
      region: REGION,
      userPoolId: USER_POOL_ID,
      userPoolWebClientId: USER_POOL_CLIENT_ID,
    },
  };

  Amplify.configure(amplifyConfig);

  const { t } = useTranslation(defaultNS);

  const formFields = {
    signUp: {},
  };

  return (
    <I18nextProvider i18n={i18n} defaultNS={defaultNS}>
      <Authenticator
        signUpAttributes={["email", "given_name", "family_name"]}
        loginMechanisms={["email"]}
        formFields={formFields}
      >
        {({ signOut, user }) => 
        {
          try 
          {
            if (window.sessionStorage)
            {
              user?.getSession(
                (error: Error | null, session: CognitoUserSession | null) => 
                {
                  if (error === null && session !== null)
                  {
                    const cognitoSession = session as CognitoUserSession;

                    const token = cognitoSession.getIdToken().getJwtToken();

                    window.sessionStorage.setItem("userToken", token);

                    const urlQueryParameters = new URLSearchParams(
                      window.location.search,
                    );

                    const redirectUrl = urlQueryParameters.get("redirectUrl");

                    if (
                      redirectUrl
                      && !window.location.pathname.includes("logout")
                    )
                    {
                      window.location.href = `${redirectUrl}${
                        !redirectUrl.includes("?") ? "?" : "&"
                      }accessToken=${cognitoSession
                        .getAccessToken()
                        .getJwtToken()}&idToken=${cognitoSession
                        .getIdToken()
                        .getJwtToken()}&refreshToken=${cognitoSession
                        .getRefreshToken()
                        .getToken()}`;
                    }
                    else if (
                      !redirectUrl
                      && !window.location.pathname.includes("logout")
                    )
                    {
                      window.location.href = LANDING_PAGE_URL;
                    }
                  }
                  else 
                  {
                    console.error(error);

                    axios
                      .post(
                        "/common/v1/log?logLevel=error",
                        JSON.stringify(
                          `error while getting session\r\n${JSON.stringify(
                            error,
                          )}`,
                        ),
                        {
                          headers: {
                            "Content-Type": "application/json",
                          },
                        },
                      )
                      .then((response) => 
                      {
                        console.debug("[App] Login error logged", {
                          response: response,
                        });
                      });
                  }
                },
              );
            }

            return (
              <div className="App">
                <header className="App-header">
                  <img src={logo} className="App-logo" alt="logo" />
                  {ENVIRONMENT_LABEL
                    && ENVIRONMENT_LABEL.toUpperCase() !== "PRODUCTION" && (
                    <span className="app-environment">
                      {ENVIRONMENT_LABEL}
                    </span>
                  )}
                </header>
                <NavLink to={"logout"} className="logout-button">
                  Logout
                </NavLink>
                <ErrorBoundary>
                  <Routes>
                    <Route
                      path="logout"
                      element={<Logout prop={t} signOut={signOut} />}
                    />
                  </Routes>
                  <Outlet />
                </ErrorBoundary>
              </div>
            );
          }
          catch (error: unknown)
          {
            console.error(error);

            axios
              .post(
                "/common/v1/log?logLevel=error",
                JSON.stringify(
                  `error while authenticating\r\n${JSON.stringify(error)}`,
                ),
                {
                  headers: {
                    "Content-Type": "application/json",
                  },
                },
              )
              .then((response) => 
              {
                console.debug("[App] Login error logged", {
                  response: response,
                });
              });

            return <></>;
          }
        }}
      </Authenticator>
    </I18nextProvider>
  );
}

export default App;